
// Vue reactivity
import { computed, defineComponent } from 'vue';

// icons
import { arrowBackOutline, close, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
        IonContent, IonGrid, IonRow, IonCol, IonItem, IonLabel,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle,
        IonList, IonListHeader, IonThumbnail,
        modalController, } from '@ionic/vue';

// composables
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BreakdownPriceModal',
  props: ['order'],
  components: { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
                IonContent, IonGrid, IonRow, IonCol, IonItem, IonLabel, 
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle,
                IonList, IonListHeader, IonThumbnail, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const { t } = useI18n();

    // 2. methods or filters
    const closeModal = async () => { await modalController.dismiss() };

    const getGroupedCanvasShapes = (order: any) => {
      const bottleId = order['樽'];
      let bottlePetType = bottleId;
      if (order['總寵物數量'] > 0) bottlePetType += `${order['總寵物數量']}隻寵物`;
      
      const groupedShapeTypes: any = {
        [bottlePetType]: {
          totalPrice: order['樽及寵物價錢'],
          groupedShapes: {
            [bottleId]: { shapes: [{ name: bottleId, photoLink: order.designPhoto }] }
          },
        }
      };
      for (const shape of order.shapes) {
        const { id, shapeId, type, lineTotal } = shape;
        const key = shapeId || id;
        if (type == '寵物') {
          groupedShapeTypes[bottlePetType].groupedShapes[key] = groupedShapeTypes[bottlePetType].groupedShapes[key] || { shapes: [] };
          groupedShapeTypes[bottlePetType].groupedShapes[key].shapes.push(shape);
        } else {
          groupedShapeTypes[type] = groupedShapeTypes[type] || { totalPrice: 0, groupedShapes: {} };
          groupedShapeTypes[type].groupedShapes[key] = groupedShapeTypes[type].groupedShapes[key] || { totalPrice: 0, shapes: [] };
          groupedShapeTypes[type].groupedShapes[key].totalPrice += Number(lineTotal || 0);
          groupedShapeTypes[type].groupedShapes[key].shapes.push(shape);
          groupedShapeTypes[type].totalPrice += Number(lineTotal || 0);
        }
      }
      return groupedShapeTypes;
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBackOutline, close,

      // variables
      

      // methods
      t, closeModal, getGroupedCanvasShapes,
    }
  },
})
