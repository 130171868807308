

import { computed, ref } from 'vue';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
        IonItem, IonLabel, IonBadge, IonThumbnail, IonList, IonListHeader, } from '@ionic/vue';
import Slides from '@/components/Slides.vue';
import BreakdownPriceModal from '@/components/BreakdownPriceModal.vue';
import PaymentInfo from '@/components/PaymentInfo.vue';

// icons
import { location, arrowBackOutline } from 'ionicons/icons';

// services
import { useRoute } from 'vue-router';
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'OrderDetailPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonCol, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
                IonItem, IonLabel, IonBadge, IonThumbnail, IonList, IonListHeader,
                Slides, PaymentInfo, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const { id, parentPath } = route.params; // post ID

    const { getOrderStatusColor, formatDateString, openModal } = utils();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingOrders);
    const order = computed(() => store.getters.getOrderById(id));

    const openBreakdownPriceModal = async () => await openModal(BreakdownPriceModal, { order: order.value });
  
    return {
      // icons
      location, arrowBackOutline,
      
      // variables
      loading, order,
      parentPath,

      // methods
      t, getOrderStatusColor, formatDateString,
      openBreakdownPriceModal,
    }
  },
}
